import React from "react";
import YearsOfExp from "../images/svg-icons/YearsOfExp.svg";
import TestCompanies from "../images/svg-icons/TestCompanies.svg";
import Counter from "./counter";

export default function LandingStats() {
  return (
    <div className="absolute inset-x-0 top-94% ">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <dl className="rounded-lg bg-gradient-to-tr from-gray-100 to-sky-100 shadow-2xl sm:grid sm:grid-cols-2">
            <div className="flex px-2 lg:px-20 w-full  justify-start sm:justify-center items-center ">
              <YearsOfExp className="w-20%" />
              <div className="flex flex-col py-6 pl-4  text-left  h-full  ">
                <dt className="order-2 mt-2 text-base font-normal tracking-normal text-gray-600 xl:text-lg  break-normal ">
                  Years of Entrepreneurial Experience
                </dt>

                <dd className="font-serif order-1 text-5xl font-extrabold text-orange-500">
                  <Counter target={450} duration={1} />+
                </dd>
              </div>
            </div>
            <div className="flex px-2 lg:px-20 w-full   justify-start sm:justify-center items-center ">
              <TestCompanies className="w-20%" />
              <div className="flex flex-col py-6 pl-4     text-left  h-full  ">
                <dt className="order-2 mt-2 text-base font-normal tracking-normal text-gray-600 xl:text-lg break-normal">
                  Team Portfolio Companies
                </dt>
                <dd className="order-1 font-serif text-5xl font-extrabold text-orange-500">
                  {" "}
                  <Counter target={450} duration={1} />+
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
