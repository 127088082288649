import React, { useEffect, useState } from 'react';
//import useWindowDimensions from "../lib/useWindowDimensions"
import LandingStats from "../components/landing_stats"
import { Link } from 'gatsby'
import Typewriter from "typewriter-effect"
import { Transition } from "@headlessui/react"

export default function LandingDemo({ location, data }) {
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [isShown, setisShown] = useState(true);
    //  const { height, width } = useWindowDimensions();

   
   

    return (
        <div className="relative w-full flex flex-col lg:flex-row  bg-gradient-to-tr from-primary-blue to-sky-700 ">
            
            {show1 ? <div className="absolute w-full h-full sm:bg-portfolioImg sm:bg-cover sm:bg-center sm:bg-no-repeat   opacity-50 ">
                <div className="bg-red-500 bg-opacity-30 w-full h-full" ></div>   
            </div> :  <div></div>   }
            
            { show2 ? <div className="absolute w-full h-full sm:bg-peopleImg  sm:bg-cover sm:bg-center sm:bg-no-repeat opacity-50">
            <div className="bg-sky-600 bg-opacity-30 w-full h-full" ></div>   

            </div> : <div></div>}

            { show3 ? <div className="absolute w-full h-full sm:bg-partnersImg  sm:bg-cover sm:bg-center sm:bg-no-repeat opacity-50">
            <div className="bg-gray-700 bg-opacity-30 w-full h-full" ></div>   

            </div> : <div></div>}
            
            

                
            
            <div className="flex flex-col justify-center items-start w-full h-90v ls:h-100v  sm:h-40v lg:w-50v lg:h-100v px-8 lg:px-16">
                <h1 className=" font-banner-heading  w-full mt-16 pt-16 lg:mt-10  text-center lg:text-left  ">
                
                <span className="absolute top-15% left-0 right-0 lg:top-30% lg:left-5% animate-fade-in-up transition duration-400 ">
                    
                            BUILDING
                    
                    </span>{' '}
                    
                    <span className="absolute top-20% left-0 right-0 lg:absolute  lg:top-40% lg:left-5% animate-fade-in-left transition duration-400">RELATIONSHIPS</span>{' '}

                    <br />
                    <div className="absolute top-25% left-0 right-0 lg:absolute  lg:top-50% lg:left-5%">
                        <span className=" text-orange-primary  ">
                 
                            <Typewriter

                            options={{
                                cursor: "",
                                delay: 100,

                            }}

                            onInit={(typewriter) => {
                                typewriter.typeString('BEYOND CAPTABLE')
                                .start();
                            }}
                            />
                            
                        </span>
                    </div>
                    <Link to="/about-us">

                        <div className="absolute top-25% left-0 right-0 lg:absolute  lg:top-50% lg:left-5%">
                            <a

                                className="  mt-16 text-xl rounded-xl  inline-flex  px-1 py-3   font-medium  text-gray-100  hover:text-orange2"
                            >
                                Discover More
                                {/* <ArrowCircleRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" /> */}
                            </a>
                        </div>
                    </Link>
                </h1>


            </div>
            <div className="relative sm:pt-12 w-full h-70v lg:w-50v ls:h-100v lg:h-100v   pb-32">
                {/* <div className="text-white">
                    width: {width} ~ height: {height}
                </div> */}
                <div onMouseEnter={() => setShow1(true)} onMouseLeave={() => setShow1(false)}>

                    <div className="absolute flex  justify-center items-center  h-60 w-60 md:h-64 md:w-64 lg:h-72 lg:w-72 xl:h-80 xl:w-80 2xl:h-96 2xl:w-96   rounded-full top-10% sm:top-40% lg:top-40% left-60% transform -translate-x-50% -translate-y-50% bg-gradient-to-br from-transparent  shadow-2xl to-orange-400  animate-fade-in-left-circle hover:scale-110  hover:transition duration-300 hover:from-orange-primary hover:to-orange-primary  z-0 hover:z-50">
                        <h1 className="font-banner-circle pl-20 text-white" >Portfolio</h1>
                    </div>

                </div>
                <div onMouseEnter={() => setShow2(true)} onMouseLeave={() => setShow2(false)}>

                    <div className="absolute flex  justify-center items-center   h-56 w-56 md:h-60 md:w-60   lg:h-64 lg:w-64 xl:h-72 xl:w-72 2xl:h-80 2xl:w-80  rounded-full top-30% sm:top-50% lg:top-50% left-35% sm:left-40% transform -translate-x-50% -translate-y-50% bg-gradient-to-br from-transparent  shadow-2xl  to-sky-50    animate-fade-in-right-circle hover:scale-110  hover:transition duration-300 hover:to-sky-600 hover:from-sky-700  z-0 hover:z-50">
                        <h1 className="font-banner-circle pr-28 text-white" >People</h1>
                    </div>

                </div>
                
                <div onMouseEnter={() => setShow3(true)} onMouseLeave={() => setShow3(false)}>

                    <div className="absolute flex  justify-center items-center    h-52 w-52 md:h-56 md:w-56  lg:h-60 lg:w-60 xl:h-64 xl:w-64 2xl:h-72 2xl:w-72  rounded-full top-40% sm:top-65%  lg:top-65% left-65% sm:left-55% transform -translate-x-50% -translate-y-50% bg-gradient-to-br from-transparent  shadow-2xl to-gray-700   animate-fade-in-down-circle hover:scale-110  hover:transition duration-300 hover:from-gray-700 hover:to-gray-800 z-0 hover:z-50">
                        <h1 className="font-banner-circle pt-32 sm:pt-32 text-white" >Network</h1>
                    </div>
                </div>    
            </div>
            

            <LandingStats />

        </div>
    )
}